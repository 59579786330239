import { LOCAL_STORE_VAR } from './../components/constant';
import * as actionCreators from './../store/actions';
import store from './../store/createStore';


export const preRefresh = () => {
    const savedCredentials = localStorage.getItem(LOCAL_STORE_VAR.credentialsKey);
    if (savedCredentials) {
        store.dispatch(actionCreators.refresh(JSON.parse(savedCredentials)));
        refresh();
    }
}

export const isAuthenticated = () => {
    if (localStorage.getItem(LOCAL_STORE_VAR.credentialsKey)) {
        return true;
    } else {
        return false;
    }
}

export const getAuthenticatedUser = () => {
    const loggedInUser = localStorage.getItem(LOCAL_STORE_VAR.credentialsKey);
    if (loggedInUser) {
        return JSON.parse(loggedInUser);
    } else {
        return null;
    }
}

export const setCredentials = (credentials) => {
    if (credentials) {
        localStorage.setItem(LOCAL_STORE_VAR.credentialsKey, JSON.stringify(credentials));
    } else {
        localStorage.removeItem(LOCAL_STORE_VAR.credentialsKey);
    }
}


export const refresh = () => {
    //let userCredentials = getAuthenticatedUser();
    /* APPLLOCLIENT
        .mutate({
            mutation: ME_MUTATION,
            variables: {}
        })
        .then(res => {
            if (res.data && res.data.auth && res.data.auth.me) {
                userCredentials.user = res.data.auth.me;
                setCredentials(userCredentials);
                store.dispatch(actionCreators.refresh(userCredentials));
            }
        }); */
}


