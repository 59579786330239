import * as actionTypes from '../actionType';

const initialState = {
    pageLoader: false,
    buttonLoader: false,
    serverErrors: [],
    isServerErrorsShow: false,
    isShowSuccessPopup: false,
    isShowErrorPopup: false,
    popupMessage: null,
    btnLabel: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_LOADER:
            return {
                ...state,
                pageLoader: true
            }
        case actionTypes.HIDE_LOADER:
            return {
                ...state,
                pageLoader: false
            }
        case actionTypes.SHOW_BUTTON_LOADER:
            return {
                ...state,
                buttonLoader: true
            }
        case actionTypes.HIDE_BUTTON_LOADER:
            return {
                ...state,
                buttonLoader: false
            }
        case actionTypes.SAVE_SERVER_ERROR:
            return {
                ...state,
                serverErrors: action.payload,
                isServerErrorsShow: false,
            }
        case actionTypes.REMOVE_SERVER_ERROR:
            return {
                ...state,
                isServerErrorsShow: false,
                serverErrors: []
            }
        case actionTypes.SHOW_SERVER_ERRORS:
            return {
                ...state,
                isServerErrorsShow: true,
            }
        case actionTypes.SHOW_SUCCESS_POPUP:
            return {
                ...state,
                isShowSuccessPopup: true,
                popupMessage: action.payload.message,
                redirectOnSuccess: action.payload.redirect,
                btnLabel: action.payload.btnLabel
            }
        case actionTypes.HIDE_SUCCESS_POPUP:
            return {
                ...state,
                isShowSuccessPopup: false,
                popupMessage: null,
                redirectOnSuccess: null,
                btnLabel: null
            }
        case actionTypes.SHOW_ERROR_POPUP:
            return {
                ...state,
                isShowErrorPopup: true,
                popupMessage: action.payload
            }
        case actionTypes.HIDE_ERROR_POPUP:
            return {
                ...state,
                isShowErrorPopup: false,
                popupMessage: null
            }

        default: {

        }
    }
    return state;
};

export default reducer;