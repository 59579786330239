import { applyMiddleware, createStore } from 'redux';
// import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { rootReducer } from './rootReducer';

// import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native


// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const loggerMiddleware = createLogger();

// const persistConfig = {
//     key: 'service_contract',
//     storage,
// }

// const persistedReducer = persistReducer(persistConfig, rootReducer);
let store;
if (process.env.GATSBY_APP_ENV === 'dev') {
    store = createStore(rootReducer, applyMiddleware(thunk, loggerMiddleware));
    // store = createStore(persistedReducer, applyMiddleware(thunk, loggerMiddleware));
} else {
    store = createStore(rootReducer, applyMiddleware(thunk));
}


// const persistor = persistStore(store)
// const createStore = () => createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, loggerMiddleware)))
// if (process.env.GATSBY_APP_ENV === 'dev') {
//     store = { store, persistor }
// }

// const store = createStore(rootReducer, applyMiddleware(thunk, loggerMiddleware));
export default store
