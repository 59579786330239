import * as actionTypes from '../actionType';

const initialState = {
    isAuthenticated: false,
    credentials: null,
    user: null,
    error: null,
    serverErrors: null,
    isShowErrors: false,
    isLoading: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_ATTEMPT:
            return {
                ...state,
                error: null,
                isLoading: true
            }
        case actionTypes.LOGIN_FAILED:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            }
        case actionTypes.SAVE_CREDENTIALS:
            return {
                ...state,
                credentials: action.payload,
                user: action.payload.user,
                error: null,
                isLoading: false,
                isAuthenticated: true
            }
        case actionTypes.ADD_ERRORS:
            return {
                ...state,
                serverErrors: action.payload,
                isShowErrors: false,
            }
        case actionTypes.CLEAR_ERRORS:
            return {
                ...state,
                isShowErrors: false,
                serverErrors: null
            }
        case actionTypes.ERRORS_SHOW:
            return {
                ...state,
                isShowErrors: true,
            }
        case actionTypes.REMOVE_CREDENTIALS:
            return {
                ...state,
                error: null,
                isAuthenticated: false,
                credentials: null,
                user: null
            }
        default: {

        }
    }
    return state;
};

export default reducer;