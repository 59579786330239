import * as actionTypes from '../actionType';

export const showLoader = () => {
    return {
        type: actionTypes.SHOW_LOADER
    };
};

export const hideLoader = () => {
    return {
        type: actionTypes.HIDE_LOADER
    };
};

export const showButtonLoader = () => {
    return {
        type: actionTypes.SHOW_BUTTON_LOADER
    };
};

export const hideButtonLoader = () => {
    return {
        type: actionTypes.HIDE_BUTTON_LOADER
    };
};

export const saveServerError = (payload) => {
    return {
        type: actionTypes.SAVE_SERVER_ERROR,
        payload
    };
};

export const removeServerError = () => {
    return {
        type: actionTypes.REMOVE_SERVER_ERROR
    };
};

export const showServerError = () => {
    return {
        type: actionTypes.SHOW_SERVER_ERRORS,
    };
};
//Modal Action
export const showSuccessPopup = (payload) => {
    return {
        type: actionTypes.SHOW_SUCCESS_POPUP,
        payload
    };
};

export const hideSuccessPopup = () => {
    return {
        type: actionTypes.HIDE_SUCCESS_POPUP,
    };
};

export const showErrorPopup = (payload) => {
    return {
        type: actionTypes.SHOW_ERROR_POPUP,
        payload
    };
};

export const hideErrorPopup = () => {
    return {
        type: actionTypes.HIDE_ERROR_POPUP,
    };
};
