import ApolloClient from "apollo-boost";
import fetch from 'isomorphic-fetch';
import store from './../store/createStore';
import * as actionCreators from './../store/actions';
import { navigate } from "gatsby"
import { isAuthenticated, getAuthenticatedUser } from "../core/util";

export const WIZARD_STEPS = {
    vehicleInfo: 1,
    yourInfo: 2,
    customizePlan: 3,
    createAccount: 4,
    securePayment: 5,
    confirm: 6
}

export const LOCAL_STORE_VAR = {
    credentialsKey: 'service_contract_credentials',
    token: 'sdfsfdsfdsfdsfdsfdsfsdftytrytry',
}

export const STRIPE_CONFIG = {
    apikey: 'pk_test_gjPIXzDXacczUfQyJoiAl6DF',
    url: 'https://api.stripe.com/v1/tokens',
}

// export const WIZARD_STEPS_NUMBER_WISE = {
//     1: 'vehicalInfo',
//     2: 'yourInfo',
//     3: 'customizePlan',
//     4: 'createAccount',
//     5: 'securePayment',
//     6: 'congratulation'
// }

// export const APPLLOCLIENT = new ApolloClient({
//     uri: process.env.GATSBY_APP_API_URL,
//     // uri: 'https://servicecontract-api.herokuapp.com/graphql',
//     fetch,
//     // headers: {
//     //   Authorization: "TEst"
//     // }
// });


export const APPLLOCLIENT = new ApolloClient({
    uri: process.env.GATSBY_APP_API_URL,
    // uri: 'http://maulik-pc:1500/graphql',
    // uri: 'http://nirav-pc:7001/graphql',
    // uri: 'https://servicecontract-api.herokuapp.com/graphql',
    fetch,
    request: async (operation) => {
        let newStore;
        // if (process.env.GATSBY_APP_ENV === 'dev') {
        //     newStore = store.store;
        // } else {
        newStore = store;
        // }
        newStore.dispatch(actionCreators.removeServerError());
        if (isAuthenticated()) {
            const token = getAuthenticatedUser().accessToken;
            if (token) {
                operation.setContext({
                    headers: {
                        Authorization: token
                    }
                });
            }
        }
    },
    onError: ({ graphQLErrors, networkError }) => {
        let newStore;
        // if (process.env.GATSBY_APP_ENV === 'dev') {
        //     newStore = store.store;
        // } else {
        newStore = store;
        // }
        newStore.dispatch(actionCreators.hideLoader());
        newStore.dispatch(actionCreators.hideButtonLoader());
        if (graphQLErrors) {
            if (graphQLErrors && graphQLErrors.length && graphQLErrors[0].message && graphQLErrors[0].message === 'INVALID_ID') {
                navigate("/vehicleInfo")
            } else {
                newStore.dispatch(actionCreators.saveServerError(graphQLErrors));
            }
        }
        if (networkError) {
            // console.log(networkError)
        }
        if (graphQLErrors) {
            // console.log(graphQLErrors)
        }
    }
});


export const stateList = [
    {"title":"Alabama","value":"AL","dealerNo":19100},
    {"title":"Alaska","value":"AK","dealerNo":0},
    {"title":"Arizona","value":"AZ","dealerNo":19100},
    {"title":"Arkansas","value":"AR","dealerNo":0},
    {"title":"California","value":"CA","dealerNo":0},
    {"title":"Colorado","value":"CO","dealerNo":0},
    {"title":"Connecticut","value":"CT","dealerNo":0},
    {"title":"Delaware","value":"DE","dealerNo":19099},
    {"title":"Florida","value":"FL","dealerNo":19102},
    {"title":"Georgia","value":"GA","dealerNo":0},
    {"title":"Hawaii","value":"HI","dealerNo":19100},
    {"title":"Idaho","value":"ID","dealerNo":0},
    {"title":"Illinois","value":"IL","dealerNo":19100},
    {"title":"Indiana","value":"IN","dealerNo":19100},
    {"title":"Iowa","value":"IA","dealerNo":19100},
    {"title":"Kansas","value":"KS","dealerNo":19099},
    {"title":"Kentucky","value":"KY","dealerNo":0},
    {"title":"Louisiana","value":"LA","dealerNo":0},
    {"title":"Maine","value":"ME","dealerNo":0},
    {"title":"Maryland","value":"MD","dealerNo":0},
    {"title":"Massachusetts","value":"MA","dealerNo":0},
    {"title":"Michigan","value":"MI","dealerNo":19099},
    {"title":"Minnesota","value":"MN","dealerNo":19100},
    {"title":"Mississippi","value":"MS","dealerNo":0},
    {"title":"Missouri","value":"MO","dealerNo":19100},
    {"title":"Montana","value":"MT","dealerNo":19100},
    {"title":"Nebraska","value":"NE","dealerNo":0},
    {"title":"Nevada","value":"NV","dealerNo":19100},
    {"title":"New Hampshire","value":"NH","dealerNo":19100},
    {"title":"New Jersey","value":"NJ","dealerNo":19100},
    {"title":"New Mexico","value":"NM","dealerNo":19100},
    {"title":"New York","value":"NY","dealerNo":19100},
    {"title":"North Carolina","value":"NC","dealerNo":19100},
    {"title":"North Dakota","value":"ND","dealerNo":19099},
    {"title":"Ohio","value":"OH","dealerNo":19099},
    {"title":"Oklahoma","value":"OK","dealerNo":19102},
    {"title":"Oregon","value":"OR","dealerNo":0},
    {"title":"Pennsylvania","value":"PA","dealerNo":19099},
    {"title":"Rhode Island","value":"RI","dealerNo":19099},
    {"title":"South Carolina","value":"SC","dealerNo":19100},
    {"title":"South Dakota","value":"SD","dealerNo":19099},
    {"title":"Tennessee","value":"TN","dealerNo":19099},
    {"title":"Texas","value":"TX","dealerNo":19100},
    {"title":"Utah","value":"UT","dealerNo":0},
    {"title":"Vermont","value":"VT","dealerNo":19100},
    {"title":"Virginia","value":"VA","dealerNo":0},
    {"title":"Washington D.C.","value":"DC","dealerNo":19100},
    {"title":"Washington","value":"WA","dealerNo":0},
    {"title":"West Virginia","value":"WV","dealerNo":19099},
    {"title":"Wisconsin","value":"WI","dealerNo":19100},
    {"title":"Wyoming","value":"WY","dealerNo":19100}
]

export const zonar = ["Alabama",
"Alaska",
"Arizona",
"Arkansas",
"California",
"Colorado",
"Connecticut",
"Delaware",
"Florida",
"Georgia",
"Hawaii",
"Idaho",
"Illinois",
"Indiana",
"Iowa",
"Kansas",
"Kentucky",
"Louisiana",
"Maine",
"Maryland",
"Massachusetts",
"Michigan",
"Minnesota",
"Mississippi",
"Missouri",
"Montana",
"Nebraska",
"Nevada",
"New Hampshire",
"New Jersey",
"New Mexico",
"New York",
"North Carolina",
"North Dakota",
"Ohio",
"Oklahoma",
"Oregon",
"Pennsylvania",
"Rhode Island",
"South Carolina",
"South Dakota",
"Tennessee",
"Texas",
"Utah",
"Vermont",
"Virginia",
"Washington",
"Washington D.C.",
"West Virginia",
"Wisconsin",
"Wyoming"
]