export const SAVE_CREDENTIALS = 'SAVE_CREDENTIALS';
export const REMOVE_CREDENTIALS = 'REMOVE_CREDENTIALS';

export const ADD_ERRORS = 'ADD_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const ERRORS_SHOW = 'ERRORS_SHOW';

export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_ATTEMPT = 'LOGIN_ATTEMPT';
export const GET_OFFICE_LIST = 'GET_OFFICE_LIST';

// Actions Starts
//Show and Hide Loader
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export const SHOW_BUTTON_LOADER = 'SHOW_BUTTON_LOADER';
export const HIDE_BUTTON_LOADER = 'HIDE_BUTTON_LOADER';

export const SAVE_SERVER_ERROR = 'SAVE_SERVER_ERROR';
export const REMOVE_SERVER_ERROR = 'REMOVE_SERVER_ERROR';
export const SHOW_SERVER_ERRORS = 'SHOW_SERVER_ERRORS';



//Save Steps
export const SAVE_FIRST_STEP = 'SAVE_FIRST_STEP';
export const SAVE_SECOND_STEP = 'SAVE_SECOND_STEP';
export const SAVE_SECOND_STEP_RESPONSE = 'SAVE_SECOND_STEP_RESPONSE';
export const SAVE_THIRD_STEP = 'SAVE_THIRD_STEP';
export const SAVE_THIRD_STEP_RESPONSE = 'SAVE_THIRD_STEP_RESPONSE';
export const SAVE_FOURTH_STEP = 'SAVE_FOURTH_STEP';
export const SAVE_FOURTH_STEP_RESPONSE = 'SAVE_FOURTH_STEP_RESPONSE';
export const SAVE_FIFTH_STEP = 'SAVE_FIFTH_STEP';
export const SAVE_FIFTH_STEP_RESPONSE = 'SAVE_FIFTH_STEP_RESPONSE';

//CLEAR STEPS
export const CLEAR_ALL_STEPS = 'CLEAR_ALL_STEPS';


//Login Action
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE';
export const LOGOUT = 'LOGOUT';

//Model Actions
export const SHOW_SUCCESS_POPUP = 'SHOW_SUCCESS_POPUP';
export const HIDE_SUCCESS_POPUP = 'HIDE_SUCCESS_POPUP';
export const SHOW_ERROR_POPUP = 'SHOW_ERROR_POPUP';
export const HIDE_ERROR_POPUP = 'HIDE_ERROR_POPUP';