// import * as constant from './constant';
import { navigate } from "gatsby";
import { LOCAL_STORE_VAR, stateList } from "../components/constant";
import store from './../store/createStore';
import * as actionCreators from './../store/actions';


export const isAuthenticated = () => {
    if (typeof window !== 'undefined') {
        const loggedInUser = localStorage.getItem(LOCAL_STORE_VAR.credentialsKey);
        if (loggedInUser) {
            return true;
        } else {
            return false;
        }
    }
}

export const getAuthenticatedUser = () => {
    if (typeof window !== 'undefined') {
        const loggedInUser = localStorage.getItem(LOCAL_STORE_VAR.credentialsKey);
        if (loggedInUser) {
            return JSON.parse(loggedInUser);
        } else {
            return null;
        }
    }
}

export const formatQueryParams = (data) => {
    if (data !== '') {
        const search = data.substring(1);
        return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
    }
}

export const restrictRouteForStep = (location) => {
    if (location &&
        (location.pathname.includes('yourInfo') ||
            location.pathname.includes('customizePlan') ||
            location.pathname.includes('createAccount') ||
            location.pathname.includes('securePayment') ||
            location.pathname.includes('confirm'))
    ) {
        if (location.pathname.includes('yourInfo')) {
            if (location.search === '') {
                const storeData = store.getState();
                if (storeData && storeData.register && storeData.register.step1 && !storeData.register.step1.vehicleInfo) {
                    navigate("/vehicleInfo"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''));
                }
            } else {
                const param = formatQueryParams(location.search);
                if (param && param.q) {
                    return param.q;
                } else {
                    const storeData = store.getState();
                    if (storeData && storeData.register && storeData.register.step1 && !storeData.register.step1.vehicleInfo) {
                        navigate("/vehicleInfo"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''));
                    }
                }
            }
        } else {
            if (location.search === '') {
                navigate("/vehicleInfo"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''));
            } else {
                const param = formatQueryParams(location.search);
                if (param && param.q) {
                    return param.q;
                } else {
                    navigate("/vehicleInfo"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''));
                }
            }
        }
    }
}

export const resetStepsData = (location) => {
    const storeData = store.getState();
    if (location && storeData && storeData.register && storeData.register.step1 && storeData.register.step1.vehicleInfo) {
        if (location && (!location.pathname.includes('vehicleInfo') &&
            !location.pathname.includes('yourInfo') &&
            !location.pathname.includes('customizePlan') &&
            !location.pathname.includes('createAccount') &&
            !location.pathname.includes('securePayment') &&
            !location.pathname.includes('confirm'))) {
            console.log('reset step');
            store.dispatch(actionCreators.ClearAllSteps());
        }
    }
}

export const showStateName = (code) => {
    const state = stateList.filter(state => state.value === code);
    if (state && state.length) {
        return state[0].title;
    } else {
        return null;
    }
}

export const getCurrentPath = () => {
    if (typeof window !== 'undefined') {
        return window.location.pathname;
    } else {
        return '';
    }
}
