import { combineReducers } from 'redux';
import authReducer from './reducers/auth.reducer';
import primaryReducer from './reducers/primary.reducer';
import registerReducer from './reducers/register.reducer';
import * as actionTypes from './actionType';

const appReducer = combineReducers({   // new rootreducer, clear store when user will logout
    auth: authReducer,
    primary: primaryReducer,
    register: registerReducer,
});

export const rootReducer = (state, action) => {
    if (action.type === actionTypes.REMOVE_CREDENTIALS) {
        state = undefined;
    }
    return appReducer(state, action)
}