import * as actionTypes from '../actionType';

export const saveFirstStep = (payload) => {
    return {
        type: actionTypes.SAVE_FIRST_STEP,
        payload
    };
};

export const saveSecondStep = (payload) => {
    return {
        type: actionTypes.SAVE_SECOND_STEP,
        payload
    };
};

export const saveSecondStepResponse = (payload) => {
    return {
        type: actionTypes.SAVE_SECOND_STEP_RESPONSE,
        payload
    };
};

export const saveThirdStep = (payload) => {
    return {
        type: actionTypes.SAVE_THIRD_STEP,
        payload
    };
};

export const saveThirdStepResponse = (payload) => {
    return {
        type: actionTypes.SAVE_THIRD_STEP_RESPONSE,
        payload
    };
};

export const saveFourthStep = (payload) => {
    return {
        type: actionTypes.SAVE_FOURTH_STEP,
        payload
    };
};

export const saveFourthStepResponse = (payload) => {
    return {
        type: actionTypes.SAVE_FOURTH_STEP_RESPONSE,
        payload
    };
};

export const saveFifthStep = (payload) => {
    return {
        type: actionTypes.SAVE_FIFTH_STEP,
        payload
    };
};

export const saveFifthStepResponse = (payload) => {
    return {
        type: actionTypes.SAVE_FIFTH_STEP_RESPONSE,
        payload
    };
};

export const ClearAllSteps = () => {
    return {
        type: actionTypes.CLEAR_ALL_STEPS
    };
};

