import * as actionTypes from './../actionType';
import { setCredentials } from '../../core/auth';
// import api from '../../api';

export const login = (value) => {
    return (dispatch, getState) => {
        // dispatch({
        //     type: actionTypes.LOGIN_ATTEMPT
        // })
        // api.post('/auth/login', value).then(res => {
        //     localStorage.setItem(constant.LOCAL_STORAGE_VAR.credentialsKey, JSON.stringify(res.data));
        //     dispatch({
        //         type: actionTypes.SAVE_CREDENTIALS,
        //         payload: res.data
        //     });
        // }).catch(err => {
        //     dispatch({
        //         type: actionTypes.LOGIN_FAILED,
        //         payload: err.response
        //     })
        // })
    }
};

export const refresh = (value) => {
    return {
        type: actionTypes.SAVE_CREDENTIALS,
        payload: value
    };
};

export const addErrors = (value) => {
    return {
        type: actionTypes.ADD_ERRORS,
        payload: value
    };
};

export const clearErrors = (value) => {
    return {
        type: actionTypes.CLEAR_ERRORS,
    };
};


export const errorShow = () => {
    return {
        type: actionTypes.ERRORS_SHOW,
    };
};

export const logout = () => {
    setCredentials();
    return {
        type: actionTypes.REMOVE_CREDENTIALS,
    };
};

export const loginResponse = (payload) => {
    return {
        type: actionTypes.SAVE_CREDENTIALS,
        payload
    };
};

