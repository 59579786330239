import React from "react"
import { Provider } from "react-redux"

import store from "./src/store/createStore"
// import App from "./App";
// import { PersistGate } from 'redux-persist/integration/react'

export default ({ element }) => {
    // return <Provider store={store}><App /></Provider>
    // if (process.env.GATSBY_APP_ENV === 'dev') {
    //     return <Provider store={store.store}><PersistGate loading={null} persistor={store.persistor}>{element}</PersistGate></Provider>
    // } else {
        return <Provider store={store}>{element}</Provider>
    // }
}