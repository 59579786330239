import * as actionTypes from '../actionType';

const initialState = {
    step1:[],
    step2:[],
    step2response: [],
    step3:[],
    step3response:[],
    step4:[],
    step4response:[],
    step5:[],
    step5response:[],
    pageLoader: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_FIRST_STEP:
            return {
                ...state,
                step1: action.payload
            }

        case actionTypes.SAVE_SECOND_STEP:
            return {
                ...state,
                step2: action.payload
            }

        case actionTypes.SAVE_SECOND_STEP_RESPONSE:
            return {
                ...state,
                step2response: action.payload
            }

        case actionTypes.SAVE_THIRD_STEP:
            return {
                ...state,
                step3: action.payload
            }

        case actionTypes.SAVE_THIRD_STEP_RESPONSE:
            return {
                ...state,
                step3response: action.payload
            }

        case actionTypes.SAVE_FOURTH_STEP:
            return {
                ...state,
                step4: action.payload
            }

        case actionTypes.SAVE_FOURTH_STEP_RESPONSE:
            return {
                ...state,
                step4response: action.payload
            }

        case actionTypes.SAVE_FIFTH_STEP:
            return {
                ...state,
                step5: action.payload
            }
        case actionTypes.SAVE_FIFTH_STEP_RESPONSE:
            return {
                ...state,
                step5response: action.payload
            }

        case actionTypes.CLEAR_ALL_STEPS:
            return {
                ...state,
                step1:[],
                step2:[],
                step2response: [],
                step3:[],
                step3response:[],
                step4:[],
                step4response:[],
                step5:[],
                step5response:[]
            }

        default: {

        }
    }
    return state;
};

export default reducer;