/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import { preRefresh } from './src/core/auth';
import { isAuthenticated, resetStepsData, restrictRouteForStep } from './src/core/util';
import wrapWithProvider from './wrap-with-provider';

// You can delete this file if you're not using it

// const React = require('react')
// const { BrowserRouter } = require('react-router-dom')
// const { Provider } = require('react-redux')
// const { applyMiddleware, compose, createStore } = require('redux')
// const thunk = require('redux-thunk')
// const { createLogger } = require('redux-logger')
// const { rootReducer } = require('./src/store/rootReducer')
// import { applyMiddleware, compose, createStore } from 'redux';



// import React from "react";
// import { BrowserRouter as Router } from "react-router-dom";
// import { Provider } from "react-redux";
// import thunk from 'redux-thunk';
// import { createLogger } from 'redux-logger';
// import { rootReducer } from "./src/store/rootReducer";


// exports.replaceRouterComponent = ({ history }) => {
//     const loggerMiddleware = createLogger();
//     const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//     const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, loggerMiddleware)));
//     const RouterWrapper = ({ children }) =>
//         <Provider store={store}>
//             <BrowserRouter history={history}>
//                 {children}
//             </BrowserRouter>
//         </Provider>;

//     return RouterWrapper;
// };

export const wrapRootElement = wrapWithProvider
/* const insertTextTspan = () => {
    const newChild = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
    var textTag = document.getElementsByTagName("text")[0];
    if (textTag !== undefined) {
        textTag.appendChild(newChild);
        document.getElementsByTagName("tspan")[1].setAttribute("x", "50");
        document.getElementsByTagName("tspan")[1].setAttribute("dy", "23");
        document.getElementsByTagName("tspan")[1].innerHTML = "Coveres";
    }
} */

export const onPreRouteUpdate = ({ location, prevLocation }) => {
    if (prevLocation === null) {
        restrictRouteForStep(location);
    }
    if (isAuthenticated()) {
        preRefresh();
    }
    resetStepsData(location);

}

export const onRouteUpdate = ({ location, prevLocation }) => {
    if (location.pathname === "/customizePlan/") {
        //insertTextTspan();
    }
}

/* const insertJS = () => {
    const addJS = (src) => {
        const s = document.createElement(`script`)
        s.type = `text/javascript`
        s.id = `stripe-js`
        s.src = src
        document.getElementsByTagName(`head`)[0].appendChild(s)
    }
    addJS('https://js.stripe.com/v3/')
} */


export const onInitialClientRender = () => {
    //insertJS();
}